import { Container, Text, Center } from "@chakra-ui/react";

export default function Footer() {
  return (
    <>
      <Container maxW="100%" h="8vh" bg="#2C3E50" centerContent>
        <Center color={"white"} h="100%">
          <Text size={"xs"}>Copyright © Houston Sikh Youth Camp 2023</Text>
        </Center>
      </Container>
    </>
  );
}

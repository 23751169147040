import Navigation from "../components/Navigation";
import ActionSection from "../components/ActionSection";
import MediaSection from "../components/MediaSection";
import CampInformationSection from "../components/CampInformationalSection";
import Announcements from "../components/Announcements";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import { Container, Alert, AlertIcon } from "@chakra-ui/react";

export default function HomePage() {
  return (
    <>
      <Navigation />
      <Container maxW={"100%"} p={"0"}>
        <Alert status="error">
          <AlertIcon />
          <p>
            Registration Closes on{" "}
            <span style={{ fontWeight: "bold" }}>
              12/18/2023 at 11:59pm CST
            </span>
          </p>
        </Alert>
        <ActionSection />
        <CampInformationSection section_name={"wintercamp"} />
        <MediaSection />
        <Announcements />
        <ContactSection />
        <Footer />
      </Container>
    </>
  );
}

export const campInformation = {
  wintercamp: {
    id: "winter-camp",
    enabled: true,
    camp_title: "WINTER CAMP 2023",
    registration_information: "REGISTRATION IS NOW OPEN",
    registration_link: "/register",
    informational_blobs: [
      {
        title: "CAMP START & DROPOFF",
        data: "Sunday, December 24th",
      },
      {
        title: "CAMP END & PICKUP",
        data: "Saturday, December 30th",
      },
      {
        title: "WHERE",
        data: "Camp Cullen, Trinity, TX",
      },
      {
        title: "WHO",
        data: "Children from Grades 1 - 12",
      },
      {
        title: "CAMP FEES",
        data: "$280 for first child\n$250 for every subsequent child after",
      },
    ],
  },
  retreat: {
    id: "retreat",
    enabled: false,
    camp_title: "RETREAT 2023",
    registration_information: "",
    registration_link: null,
    informational_blobs: [
      {
        title: "RETREAT START & DROPOFF",
        data: "Friday, September 1s",
      },
      {
        title: "RETREAT END & PICKUP",
        data: "Monday, September 4th",
      },
      {
        title: "WHERE",
        data: "CYJ Retreat Center, Woodcreek, TX",
      },
      {
        title: "WHO",
        data: "Rising college juniors and above",
      },
      {
        title: "CAMP FEES",
        data: "$250 for college students\n$300 for working professionals",
      },
      {
        title: "CONTACT",
        data: "texassikhretreat@gmail.com",
      },
    ],
  },
};

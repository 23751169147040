import {
  Container,
  Heading,
  Center,
  VStack,
  Divider,
  Link,
} from "@chakra-ui/react";
import * as dayjs from "dayjs";

export default function ActionSection() {
  return (
    <>
      <Center
        h={"25vh"}
        bg={"lightblue"}
        textColor={"white"}
        id="action-section"
      >
        <VStack>
          <Heading as="h1" size={"3xl"}>
            HOUSTON SIKH YOUTH CAMP
          </Heading>
          {/* {dayjs().isBefore(dayjs("2023-12-18 23:59")) && (
            <Heading as="h3" size={"lg"} color="red">
              <Link href="/register">REGISTRATION IS NOW OPEN</Link>
            </Heading>
          )} */}
          {/* <Divider color={"black"} /> */}
          <Heading as="h3" size={"lg"}>
            Sangat - Seva - Simran
          </Heading>
        </VStack>
      </Center>
    </>
  );
}

import {
  Container,
  Heading,
  Text,
  Divider,
  HStack,
  Link,
  Center,
  VStack,
} from "@chakra-ui/react";

import { FaExternalLinkAlt } from "react-icons/fa";

export default function Announcements() {
  //     YMCA CAMP CULLEN
  // 460 Cullen Loop
  // Trinity, Texas 75862

  // YMCA Camp Cullen website
  return (
    <>
      <Container
        centerContent
        bg="lightblue"
        maxW="100%"
        p={"2em"}
        color="white"
        h={"35vh"}
        id="camp-venue"
      >
        <Heading as={"h3"} size={"2xl"} color="white" mb={"0rem"}>
          CHECK OUT OUR CAMP VENUE
        </Heading>
        <Divider mb={"1rem"} maxW="50%" height="0em" color={"black"} />
        <Center h="15vh" mt="0rem">
          <VStack>
            <Heading as={"h3"} size={"lg"} color="white" mb={"0.25rem"}>
              <Link href="https://ymcacampcullen.org/" isExternal>
                YMCA CAMP CULLEN
              </Link>
            </Heading>
            <Heading as={"h3"} size={"md"} color="gray.100" mb={"1rem"}>
              <Link href="https://maps.app.goo.gl/SPjrrhDi9ajNhMHaA" isExternal>
                460 Cullen Loop Trinity, Texas 75862
              </Link>
            </Heading>
          </VStack>
        </Center>
      </Container>
    </>
  );
}

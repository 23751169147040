import {
  Container,
  Heading,
  Divider,
  Text,
  Circle,
  HStack,
} from "@chakra-ui/react";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { FaSoundcloud } from "react-icons/fa";
export default function MediaSection() {
  const mediaTypes = [
    {
      name: "Instagram",
      icon: <BsInstagram style={{ color: "white" }} />,
      link: "https://www.instagram.com/houstonsikhyouthcamp/?hl=en",
    },
    {
      name: "Facebook",
      icon: <BsFacebook style={{ color: "white" }} />,
      link: "https://www.facebook.com/p/Houston-Sikh-Youth-Camp-100064290505314/",
    },
    {
      name: "Soundcloud",
      icon: <FaSoundcloud style={{ color: "white" }} />,
      link: "https://soundcloud.com/houston-sikh-youth-camp",
    },
    {
      name: "Twitter",
      icon: <BsTwitter style={{ color: "white" }} />,
      link: "https://twitter.com/houstonsikhcamp",
    },
  ];
  return (
    <>
      <Container centerContent bg="#2C3E50" maxW="100%" p={"2em"} id="media">
        <Heading as={"h3"} size={"xl"} color="white">
          MEDIA
        </Heading>
        <Divider mb={"0.5rem"} maxW="25%" height="2em" />
        <iframe
          style={{ width: "75vw", height: "75vh", marginBottom: "1rem" }}
          src="https://www.youtube.com/embed/XUqjLX9Uxfw?si=X1KoHR6T9yeuDQrf"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <Text color={"white"} as="b" fontSize={"xl"}>
          AROUND THE WEB
        </Text>

        {/* <Divider maxW={"10%"} /> */}
        <HStack>
          {mediaTypes.map((media) => {
            return (
              <a href={`${media.link}`} target="_blank">
                <Circle
                  mt={"1rem"}
                  h={"3rem"}
                  w={"3rem"}
                  border="2px"
                  borderColor="white"
                  centerContent
                  rounded={"100%"}
                  // _hover=
                  _hover={{
                    background: "gray.500",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {media.icon}
                </Circle>
              </a>
            );
          })}
        </HStack>
      </Container>
    </>
  );
}
